import { Box, Text, Button } from '@chakra-ui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { getGatsbyImageData } from '../../utils/getGatsbyImageProps'

const FilterItem = ({
    innerRef,
    displayTitle,
    title,
    imageFormats,
    selected = false,
    onClick = () => {},
    ...props
}) => {
  let image
  if (imageFormats?.image?.asset) image = imageFormats?.image
  if (imageFormats?.squareImage?.asset) image = imageFormats?.squareImage
  return <Button
    sx={{
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'block',
      '&:focus-within': {
        '> div': {
          // boxShadow: '0 0 0 5px #000'
          transform: `scale(1.05)`
        }
      }
    }}
    _focusVisible={'none'}
    variant={'link'}
    onClick={onClick}
    ref={innerRef}
  >
    <Box
      sx={{
        borderRadius: '50%',
        overflow: 'hidden',
        boxShadow: selected ? '0 0 0 5px #000' : 'none',
        bg: 'gray.1',
        transition: 'transform 200ms linear',
        width: 'calc(100% - 40px)',
      }}
    >
      {image?.asset ?
        <GatsbyImage
          image={
            getGatsbyImageData(image,
              {
                square: true,
                placeholder: 'dominantColor'
              })
          }
          alt={image.asset.altText || displayTitle || title}/>
        : <img width={'100%'} height={'auto'} src={'https://via.placeholder.com/600x600'} alt={'placeholder'}/>}
    </Box>
    <Text
      as='span'
      position='absolute'
      bottom={0}
      right={0}
      transform='rotate(-90deg) translate(100%, 0)'
      transformOrigin='right bottom'
      textStyle={'rism'}
      casing={'initial'}
    >{displayTitle || title}</Text>
  </Button>
}

export default FilterItem
