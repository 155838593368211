
/**
 * @param groups
 * @returns {*}
 */
const convertGroupsToOptions = groups => groups.flatMap(group => (
    group.options.flatMap(option => ({
      ...option,
      group: group.title
    }))
  )
)

/**
 * @type {(function(*): ([]|*[]))|*}
 */
const getSanityAttributeOptions = ((sanityAttribute, pathname) => {
  if (!sanityAttribute) return []
  const sanityOptions = sanityAttribute.options || []
  // get array of options for each group append them to the options
  if (!sanityAttribute.groups || !sanityAttribute.groups.length) return sanityOptions

  // @TODO rethink this, pageref is only used here?
  // group filter base on pathname for category attribute
  if (sanityAttribute.namedTag === 'category') {
    return [
      ...sanityOptions,
      ...convertGroupsToOptions(
        sanityAttribute.groups.filter(({ pageRef }) => (
          pageRef && pageRef.slug.current && pathname.replaceAll('/', '') === pageRef.slug.current
        ))
      )
    ]
  }
  return [...sanityOptions, ...convertGroupsToOptions(sanityAttribute.groups)]
})

export default getSanityAttributeOptions