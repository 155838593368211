import { Heading, SimpleGrid, forwardRef } from '@chakra-ui/react'
import React from 'react'
import useSanityRefinementList from '../hooks/useSanityRefinementList'
import { FilterCategory, FilterItem } from './index'

const FilterContent = ({ ...props }, ref) => {
  const { attribute } = props
  const {
    items,
    createURL,
    refine,
  } = useSanityRefinementList({ attribute })
  if (attribute === 'category') {
    return <FilterCategory {...props} innerRef={ref} />
  }
  /**
   * group algoliaItems by a key (item.group) so that we can handle unified rendering
   * items without group will be grouped with an undefined title
   */
  const itemsByGroup = items.reduce((acc, item) => {
    const uniqueKey = item.group
    if (!acc[uniqueKey]) {
      acc[uniqueKey] = {
        title: item.group,
        items: []
      }
    }
    acc[uniqueKey].items.push(item)
    return acc
  }, [])

  // @TODO check the implementation of 'groupsAndOptions', 'groups'
  return <React.Fragment>
    {/*{[ 'groupsAndOptions', 'groups' ].includes(sanityAttribute.type) && <FilterGroups*/}
    {/*  sanityAttribute={sanityAttribute}*/}
    {/*  {...props}*/}
    {/*/>}*/}
    {/*{[ 'groupsAndOptions', 'options' ].includes(sanityAttribute.type) && <FilterOptions*/}
    {/*  options={sanityAttribute.options}*/}
    {/*  {...props}*/}
    {/*/>}*/}
    {Object.values(itemsByGroup).map((itemGroup, groupIdx) => <React.Fragment key={`filter-content-${itemGroup.title}`}>
        {itemGroup.title !== undefined && <Heading
          mx={'5rem'}
          size={'xl'}
          variant={'caps'}
          lineHeight={'tall'}
          textAlign={'center'}
          borderBottom={'1px solid black'}
          py={'1rem'}
          mb={'2rem'}
          top={0}
          height={'8rem'}
          position={'sticky'}
          zIndex={'sticky'}
          bg={'white'}
        >{itemGroup.title}</Heading>}
        <SimpleGrid spacing={'5rem'} py={'5rem'} px={'5rem'} columns={[2, null, 3, 4, null, 6]}>
          {itemGroup.items.map((item, idx) =>
            <FilterItem
              {...(groupIdx === 0 && idx === 0 && { innerRef: ref })}
              {...item}
              href={createURL(item.value)}
              selected={item.isRefined}
              onClick={event => {
                event.preventDefault()
                refine(item.value)
              }}
              key={`filter-item-${item._id}`}
            />
          )}
        </SimpleGrid>
      </React.Fragment>
    )}
  </React.Fragment>
}

export default forwardRef(FilterContent)