import React, { useContext } from 'react'
import { useLocation } from '@reach/router'
import { useCurrentRefinements } from 'react-instantsearch-hooks-web'
import { ConfigContext } from 'layouts'
import getSanityAttributeOptions from '../utils/getSanityAttributeOptions'
import getSanityFilteredOptions from '../utils/getSanityFilteredOptions'

/**
 * This function is wrapping algolia useCurrentRefinements and merges data with Sanity
 * @param props
 * @returns {{createURL: CreateURL<CurrentRefinementsConnectorParamsRefinement>, refine(refinement: CurrentRefinementsConnectorParamsRefinement): void, items: (*&{refinements: *})[], canRefine: boolean}}
 */
 // @TODO: useSanityCurrentAttributeRefinements (?)
const useSanityCurrentAttributeRefinements = (attribute) => {
  const {
    items: algoliaItems,
    ...currentRefinementList
  } = useCurrentRefinements({ includedAttributes: [attribute] })
  const { pathname } = useLocation()
  const config = useContext(ConfigContext)
  const sanityAttribute = config.getAttribute(attribute)
  const items = !sanityAttribute ? algoliaItems : algoliaItems.map(algoliaItem => {
    const sanityOptions = getSanityAttributeOptions(sanityAttribute, pathname)
    const refinements = getSanityFilteredOptions(algoliaItem.refinements, sanityOptions)
    return { ...algoliaItem, refinements }
  })
  // this should warn when sanityOptions are empty but algolia has items
  if (!items.length && currentRefinementList.canRefine) {
    console.warn('Maybe we need to set canRefine to false also here')
  }

  return { ...currentRefinementList, items }
}

export default useSanityCurrentAttributeRefinements