/**
 * This function filters algolia items to match sanity options found based on title
 * algolia items are sorted in the order that sanity options are listed
 * the algolia object and the sanity option are merged into a single object
 *
 * @TODO move ot utils
 *
 * @param algoliaItems
 * @param sanityOptions
 * @returns {*}
 */
const getSanityFilteredOptions = (algoliaItems, sanityOptions) => sanityOptions.map(sanityOption => {
  const match = algoliaItems.find(algoliaItem => algoliaItem.label === sanityOption.title)
  if (!match) return
  return { ...match, ...sanityOption }
}).filter(Boolean)

export default getSanityFilteredOptions