import React, { useContext, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Flex, Text, ButtonGroup, Button, useDisclosure } from '@chakra-ui/react'
import { FocusLock } from '@chakra-ui/focus-lock'
// import { RemoveScroll } from 'react-remove-scroll'
import { useClearRefinements } from 'react-instantsearch-hooks-web'
import { ConfigContext } from 'layouts'
import useSanityRefinementList from 'gatsby-plugin-algolia/hooks/useSanityRefinementList'
import useSanityCurrentAttributeRefinements from 'gatsby-plugin-algolia/hooks/useSanityCurrentAttributeRefinements'
import { FilterContent, FilterTrigger, SelectedFilters } from './index'

const Overlay = ( { children, isOpen, linkRef, ...props } ) => {
  const [posY, setY] = useState(0)
  useEffect(() => {
    if (linkRef && linkRef.current) {
      if (isOpen) {
        // this simulates the "hiding" of the header menu bar
        linkRef.current.scrollIntoView({ behavior: 'smooth' })
      } else {
        // scroll to top on close
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      // measurements
      const rect = linkRef.current ? linkRef.current.getBoundingClientRect() : {}
      setY(rect.top + rect.height)
    }
  }, [isOpen])

  return <Flex
    zIndex='overlay'
    direction='column'
    sx={{
      transition: `transform 300ms ease`,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      background: `#fff`,
      mt: 0,
      py: 0,
      px: 0,
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
    }}
    style={{
      visibility: isOpen ? `visible` : `hidden`,
      transform: isOpen ? `translateY(0px)` : `translateY(${posY}px)`,
      height: '100vh',
      maxHeight: '100vh'
    }}
    {...props}
  >
    {children}
  </Flex>
}

const FilterModal = ({ attribute, closeOnEsc = true, idx }) => {
  const linkRef = useRef()
  const itemRef = useRef()

  const config = useContext(ConfigContext)
  const sanityAttribute = config.getAttribute(attribute)
  const { items } = useSanityCurrentAttributeRefinements(attribute)
  const { refine } = useClearRefinements({ includedAttributes: [attribute] })

  const { isOpen, onOpen, onClose, getDisclosureProps, getButtonProps } = useDisclosure()
  const { hidden, id } = getDisclosureProps() // we need to pass `hidden` to `aria-hidden`

  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/modal/src/use-modal.ts#L104
  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation()
        if (closeOnEsc) {
          onClose?.()
        }
      }
    },
    [closeOnEsc, onClose],
  )

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, { passive: true })
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  // set scroll lock class on body (or html in this case)
  useEffect(() => {
    // document.body
    document.documentElement.classList.toggle('scroll-lock', isOpen)
    return () => document.documentElement.classList.remove('scroll-lock')
  }, [isOpen])

  const handleReset = () => refine()

  // we hide the Filter Modal if no items were passed from Algolia
  const { items: listItems } = useSanityRefinementList({ attribute })
  if (listItems && !listItems.length) {
    return null
  }

  return <React.Fragment>
    <FilterTrigger
      attribute={attribute}
      ref={linkRef}
      handleClick={onOpen}
      {...getButtonProps()}
    />
    <FocusLock
      autoFocus={true}
      isDisabled={!isOpen}
      initialFocusRef={itemRef}
      finalFocusRef={linkRef}
      restoreFocus={true}
      lockFocusAcrossFrames={true}
    >
{/*    <RemoveScroll
        removeScrollBar={false}
        allowPinchZoom={false}
        enabled={isOpen}
        forwardProps
      >*/}
        <Overlay
          isOpen={isOpen}
          linkRef={linkRef}
          id={id}
          aria-hidden={hidden}
          role='dialog'
          tabIndex='-1'
          aria-modal='true'
          aria-describedby={`chakra-modal--body-:r${idx}:`}
        >
          <Flex
            px={'5rem'}
            sx={{
              height: '8rem',
              width: '100%',
              overflow: 'hidden',
              borderBottom: '1px solid black',
              alignItems: 'center',
              flexWrap: `wrap`,
              backgroundColor: 'white'
            }}
          >
            <Text as='span' textStyle='rixl' mr={'0.5rem'}>{/* isOpen ? `↑` : `↓` */} {sanityAttribute.filteringAndSearch?.prefix}&nbsp;</Text><SelectedFilters attribute={attribute} handleClick={onClose} imageOnly />
            <Box
              position='absolute'
              right={0}
              top={0}
            >
              {
                isOpen &&
                <ButtonGroup isAttached sx={{ height: '8rem', borderBottom: '1px solid', borderLeft: '1px solid', bg: 'white' }}>
                  {items?.length > 0 &&
                    <Button borderRight={'1px solid'} px={6} py={'1rem'} size={'xl'}  variant='link' onClick={handleReset}>Reset</Button>
                  }
                  <Button px={'5rem'} py={'1rem'} size={'xl'}  variant='link' onClick={onClose}>Ok</Button>
                </ButtonGroup>
              }
            </Box>
          </Flex>
          <Box
            id={`chakra-modal--body-:r${idx}:`}
            overflow='auto'
            flex='1'
            sx={{
              transform: isOpen ? `translateZ(0)` : `translate3d(0,60px,0)`,
              willChange: `transform, opacity`,
              opacity: isOpen ? 1 : 0,
              transition: `transform 2s cubic-bezier(.16,1.08,.38,.98), opacity .5s ease-in`
            }}
          >
            <FilterContent attribute={attribute} ref={itemRef} />
          </Box>
        </Overlay>
      {/*</RemoveScroll>*/}
    </FocusLock>
  </React.Fragment>
}

export default FilterModal
