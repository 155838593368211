import React, { useContext } from 'react'
import { Box, Text, Button } from '@chakra-ui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ConfigContext } from '../../layouts'
import { getGatsbyImageData } from '../../utils/getGatsbyImageProps'
import useSanityCurrentAttributeRefinements from '../hooks/useSanityCurrentAttributeRefinements'

const SelectedFilters = ({ imageOnly, innerRef, attribute, handleClick = () => {}, ...props }) => {
  const config = useContext(ConfigContext)
  const sanityAttribute = config.getAttribute(attribute)
  const { items } = useSanityCurrentAttributeRefinements(attribute)
  if (!items || !items.length) {
    return <Button display='inline-flex' alignItems='center' onClick={handleClick} {...props} variant='unstyled' size='xl' ref={innerRef} _focusVisible={{ boxShadow: '0 0 0 3px #000' }}>
      <Text
        as='span'
        textStyle='rixl'
        variant='caps'
        textDecoration='underline'
        cursor='pointer'
      >{sanityAttribute.filteringAndSearch?.defaultText}</Text>
    </Button>
  }
  return <React.Fragment>
    {items.map(item => {
      return item.refinements.map((refinement, idx) => {
          const isList = refinement.key === 'category'
          return <React.Fragment key={`selected-filters-${refinement.label}`}>
            {(!imageOnly || isList) && idx !== item.refinements.length && idx !== 0 && <Text as='span' textStyle='rixl' px='1'>/</Text>}
            <Button display='inline-flex' alignItems='center' onClick={handleClick} {...props} variant='unstyled' size='xl' {...(idx === 0 && { ref: innerRef })} _focusVisible={{ boxShadow: '0 0 0 3px #000' }}>
              {
                !isList &&
                <Box
                  as='figure'
                  sx={{
                    width: '5rem',
                    height: '5rem',
                    borderRadius: '50%',
                    overflow: 'hidden'
                  }}
                  bg='secondary'
                  mr='2'
                >
                  {refinement?.imageFormats?.squareImage?.asset && <GatsbyImage
                    image={getGatsbyImageData(refinement.imageFormats.squareImage, { square: true, placeholder: 'dominantColor' })}
                    alt={refinement.imageFormats.squareImage.asset.altText || ''}/> }
                </Box>
              }
              { (!imageOnly || isList) && <Text
                as='span'
                textStyle='rixl'
                variant='caps'
                decoration='underline'
                cursor='pointer'
              >{refinement.displayTitle || refinement.title}</Text> }
            </Button>
          </React.Fragment>
        }
      )
    })}
  </React.Fragment>
}

export default SelectedFilters
