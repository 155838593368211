import React, { useContext, forwardRef } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { ConfigContext } from '../../layouts'
import SelectedFilters from './SelectedFilters'

const FilterTrigger = ({ attribute, children, ...props }, ref) => {
  const config = useContext(ConfigContext)
  const sanityAttribute = config.getAttribute(attribute)
  return (
    <Box as='header' position='relative' px={'5rem'}>
      <Flex alignItems='center' wrap='wrap'>
          <Text as='span' textStyle='rixl'>{sanityAttribute.filteringAndSearch?.prefix}&nbsp;</Text>
          <SelectedFilters attribute={attribute} {...props} innerRef={ref}/>
      </Flex>
      {children}
    </Box>
  )
}

export default forwardRef(FilterTrigger)
