import { Flex, Link, SimpleGrid, Text } from '@chakra-ui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { getGatsbyImageData } from '../../utils/getGatsbyImageProps'
import useSanityRefinementList from '../hooks/useSanityRefinementList'

const CheckLink = ({ innerRef, createURL, refine, setImage, children, item }) => {

  // onMouseEnter only setImage _key
  const handleMouseEnter = () => {
    // console.log('onMouseEnter: ', item?.imageFormats?.squareImage?.asset?.id)
    setImage && item?.imageFormats?.squareImage && setImage(item.imageFormats.squareImage)
  }

  return <Link
    ref={innerRef}
    onMouseEnter={handleMouseEnter}
    textStyle='rixl'
    _before={{
      display: 'inline-flex',
      paddingRight: item.isRefined ? '1rem' : 0,
      content: item.isRefined ? '"×"' : `''`,
    }}
    _hover={{
      textDecoration: 'none',
      ':before': {
        paddingRight: '1rem',
        content: '"×"',
      }
    }}
    pt={'1rem'}
    href={createURL(item.value)}
    onClick={event => {
      event.preventDefault()
      refine(item.value)
    }}
  >
    <span>{children}</span>
  </Link>
}

const FilterCategory = ({ innerRef, attribute, heading, ...props }) => {
  const {
    items,
    createURL,
    refine,
  } = useSanityRefinementList({ attribute })
  const [image, setImage] = useState(false)

  return <SimpleGrid columns={2} spacing={'5rem'} py={'5rem'} px={'5rem'}>
    <Flex direction='column' alignItems='left'>
      {heading && <Text as='div' textStyle='rixl' variant='caps'>{heading}</Text>}
      {items.map((item, idx) => <CheckLink
        key={item._id + 'checklink'}
        {...(idx === 0 && { innerRef })}
        setImage={setImage}
        item={item}
        createURL={createURL}
        refine={refine}
        {...props}
      >
        {item.displayTitle || item.title}
      </CheckLink>)}
    </Flex>
    <Flex mt={'5rem'} direction='column' alignItems='left'>
      {image &&
        <GatsbyImage
          image={
            getGatsbyImageData(image,
              {
                square: true,
                placeholder: 'dominantColor'
              })
          }
          alt={image.asset.altText || ''}/>
      }
    </Flex>
  </SimpleGrid>
}

export default FilterCategory
