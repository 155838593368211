import React, { useContext } from 'react'
import { useLocation } from '@reach/router'
import { ConfigContext } from 'layouts'
import { useRefinementList } from 'react-instantsearch-hooks-web'
import getSanityAttributeOptions from '../utils/getSanityAttributeOptions'
import getSanityFilteredOptions from '../utils/getSanityFilteredOptions'

/**
 * This function is wrapping algolia useRefinementList and merges data with Sanity
 * @param attribute
 * @param props
 * @returns {{isShowingMore: boolean, canToggleShowMore: boolean, sendEvent: SendEventForFacet, searchForItems(query: string): void, hasExhaustiveItems: boolean, createURL: CreateURL<string>, refine(value: string): void, toggleShowMore(): void, isFromSearch: boolean, items: *, canRefine: boolean}}
 */
const useSanityRefinementList = ({ attribute, ...props }) => {
  const {
    items: algoliaItems,
    ...refinementList
  } = useRefinementList({ attribute, ...props })

  const { pathname } = useLocation()
  const config = useContext(ConfigContext)
  const sanityAttribute = config.getAttribute(attribute)

  const sanityOptions = getSanityAttributeOptions(sanityAttribute, pathname)
  const items = !sanityAttribute ? algoliaItems : getSanityFilteredOptions(algoliaItems, sanityOptions)

  if (!sanityAttribute) {
    console.warn('Sanity Attribute ', attribute, ' is missing!')
  }

  // this should warn when sanityOptions are empty but algolia has items
  if (!items.length && refinementList.canRefine) {
    console.warn('Maybe we need to set canRefine to false also here')
  }

  return { ...refinementList, items }
}

export default useSanityRefinementList