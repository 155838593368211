import React, { useState } from 'react'
import { Configure } from 'react-instantsearch-hooks-web'
import { Box, Flex, Link, SimpleGrid, Text } from '@chakra-ui/react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import { useScrollPosition } from 'utils/useScrollPosition'
import useStore from 'stores/useStore'
import Transition from 'components/layout/Transition'
import { Subheader } from 'components/layout'
import { AlgoliaSearch, InfiniteScroll } from '../gatsby-plugin-algolia'
import { ProductListItem } from '../components/product'
import { FilterModal } from '../gatsby-plugin-algolia/category'

const sortingArr = ['category', 'upholstery', 'upholstery_color', 'upholstery_texture', 'metal_finish', 'wood_option', ]

const CategoryPage = ({data, pageContext, location}) => {
    // we currently only have one indice
    // const searchIndices = [{name: `products`, title: `Products`}]
    const {
      filters
    } = pageContext

    // https://stackoverflow.com/a/44063445/497344
    const sortedFilters = (data?.filters?.nodes || []).slice().sort((a, b) => sortingArr.indexOf(a.namedTag) - sortingArr.indexOf(b.namedTag))

    // @TODO: revisit this... the pageType conditional logic is only partially complete
    const breadcrumbs = [
      { title: 'Shop', link: '#' },
      ...(pageContext.filters.map(({ value }) => ({ title: value, link: `/${value.toLowerCase()}` })))
    ]

    const parsedFilters = filters.map(({type, field, value}, index) => {
        const filterString = `${field}:${value}`;
        if (index === 0) {
            return filterString
        }
        return `${type} ${filterString}`
    })

    const [scrollingUp, setScrollingUp] = useState(true)
    const openMenu = useStore(store => store.openMenu)

    useScrollPosition(({ prevPos, currPos }) => {
      const scrollingDown = currPos.y > prevPos.y
      if (!scrollingDown) {
        setScrollingUp(true)
      } else {
        setScrollingUp(false)
      }
    }, [])

    // console.log('parsedFilters: ', parsedFilters.join(' '))
    // https://www.algolia.com/doc/api-reference/api-parameters/filters/?client=javascript#examples

    return (
        <Transition>
          <Subheader
            leftComponent={
              (breadcrumbs || []).map((item, count) => (
                <Text textStyle='rimd' variant={item.link === '#' ? 'caps' : ''} key={item.title} lineHeight='short'>
                  <Link
                    as={GatsbyLink}
                    to={item.link}
                    onClick={(event) => item.link === '#' && openMenu(event, item.title)}
                  >{item.title}</Link>
                  &nbsp;{ breadcrumbs.length - 1 !== count && <span>/&nbsp;</span>}
                </Text>
              ))
            }
            backToTop={'↥ Filters'}
          />
          <AlgoliaSearch routing={true}>
            <Configure
              filters={parsedFilters.join(' ')}
              hitsPerPage={4}
              analytics={false}
              enablePersonalization={false}
            />
            <Flex py={'5rem'}>
              <Box flex={`1 1 50%`}>
                {sortedFilters && sortedFilters.map((attribute, idx) => <FilterModal
                    key={`filter-modal-${attribute.namedTag}`}
                    attribute={`${attribute.namedTag}`}
                    limit={50}
                    idx={idx}
                  />
                )}
              </Box>
            </Flex>
            <SimpleGrid spacing={'5rem'} columns={[1, null, null, 2, null, 3, 4]} px={'5rem'} py={'5rem'}>
              <InfiniteScroll hitComponent={ProductListItem}/>
            </SimpleGrid>
          </AlgoliaSearch>
        </Transition>
    )
}

export default CategoryPage

export const query = graphql`
  query PageTemplateQuery {
    filters: allSanityAttribute(
      filter: {filteringAndSearch: {isAttributeForFaceting: {eq: true}}, active: {eq: true}}
    ) {
      nodes {
        filteringAndSearch {
          isSearchableAttribute
          isAttributeForFaceting
          prefix
          defaultText
        }
        title
        namedTag
        groups {
          title
          options {
            title
          }
        }
        options {
          title
        }
      }
    }
  }
`
